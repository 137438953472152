const Theme = {
  colors: {
    black: '#000000',
    white: '#ffffff',
    green: '#1ad933',
    amber: '#ff9d0a',
    red: '#d91a32',
    gray: '#8e8e93',
    grayLight: '#f2f2f7',
    grayDark: '#1d1d1f',
  },
  breakpoints: {
    medium: '850px',
    small: '425px',
  },
};

export default Theme;