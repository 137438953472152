/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import MovieLandingsPage from '../videos/biking.mp4';
import '@fontsource/poppins';
import { Link } from 'gatsby';

const Container = styled.div`
  padding: 5rem 5rem 5rem 5rem;
  height: 100vh;
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.medium}) {
    padding: 5rem 2rem 5rem 2rem;
  }
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.small}) {
      padding: 10rem 1rem 15rem 1rem;
  }
  background: white;
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-attachment: scroll;
  overflow: hidden;
`;

const Movie = styled.video`
  height: 100%;
  width: 100%;
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.medium}) {
      object-fit: cover;
      width: 100%;
  }
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.small}) {
      object-fit: cover;
      width: 100%;
  }
`;

const Block = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 40%;
  left: 15%; 
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.medium}) {
      top: 43%;
      left: 5%;
  }
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.small}) {
      top: 45%;
      left: 5%;
  }
`

const TextBox = styled(Link)`
  font-weight: 800;
  z-index: 3;
  text-decoration: none;
  letter-spacing: 0rem;
  color: ${(props) => props.theme.colors.white};
  margin: 0rem;
  display: box;
  padding: 0rem 2rem 0.5rem 2rem;
  border: 5px solid ${(props) => props.theme.colors.white};
  font-size: 4rem;
  line-height: 5rem;
  margin-bottom: 0rem;
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.medium}) {
      letter-spacing: 0rem;
      padding: 0rem 2rem 0.5rem 2rem;
      border: 4px solid ${(props) => props.theme.colors.white};
      font-size: 4rem;
      line-height: 4rem;
  }
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.small}) {
      letter-spacing: 0rem;
      padding: 0rem 1rem 0.5rem 1rem;
      border: 2px solid ${(props) => props.theme.colors.white};
      font-size: 2rem;
      line-height: 2rem;
  }
`;


const Text = styled(Link)`
  z-index: 3;
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  margin: 0rem;
  padding: 0.5rem 2rem 0.5rem 0rem;
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin-bottom: 0rem;
  max-width: 30rem;
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.medium}) {
      padding: 0.2rem 2rem 0.5rem 0rem;
      font-size: 0.8rem;
      line-height: 1.2rem;
      max-width: 25rem;
  }
  @media all and (max-width: ${(props) =>
    props.theme.breakpoints.small}) {
      padding: 0.2rem 2rem 0.5rem 0rem;
      font-size: 0.7rem;
      line-height: 1rem;
      max-width: 15rem;
  }
`;

export default function Video() {
  return (
    <Container>
        <Wrapper>
            <Block>
                <TextBox href="https://www.linkedin.com/in/wietsedewindt/">I am biking.</TextBox>
                <Text href="https://www.linkedin.com/in/wietsedewindt/">Contact me via LinkedIn and I will respond when I am back.</Text>
            </Block>
            <Movie
            src={MovieLandingsPage}
            autoPlay="autoPlay"
            loop="loop"
            muted="muted"
            playsInline
            crossOrigin="anonymous"
            >
            <source
                src={MovieLandingsPage}
                type="video/mp4"
            />
            </Movie>
        </Wrapper>
    </Container>
  );
}