import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Theme from './theme';
import '@fontsource/poppins';
import '@fontsource/poppins/200.css';
import { motion } from "framer-motion"

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
    font-family: "poppins", sans-serif;
    height: 100vh;
  }
  h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
  }
  ol, ul {
    list-style: none;
  }
  h1 {
    font-weight: 200;
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 2rem;
    @media all and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
      font-size: 3rem;
      line-height: 4rem;
      margin-bottom: 1.5rem;
    }
    @media all and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
      font-size: 2.5rem;
      line-height: 3rem;
      margin-bottom: 1rem;
    }
  }
  h2 {
    font-weight: 200;
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 1.5rem;
    @media all and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
      font-size: 2.5rem;
      line-height: 3rem;
      margin-bottom: 1rem;
    }
    @media all and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 1rem;
    }
  }
  p {
    font-size: 1rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    @media all and (max-width: ${(props) =>
      props.theme.breakpoints.medium}) {
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }
    @media all and (max-width: ${(props) =>
      props.theme.breakpoints.small}) {
      font-size: 0.8rem;
      line-height: 1.2rem;
      margin-bottom: 1rem;
    }
  }
`;

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <motion.main
          initial={{
            opacity: 0,
            scale: 0.8
          }}
          animate={{
            opacity: 1,
            scale: 1
          }}
          exit={{
            opacity: 0
          }}
          transition={{
            duration: 1
          }}
        >
          {children}
        </motion.main>

      {/* {children} */}
    </ThemeProvider>
  );
}


{/* <motion.main
initial={{
  opacity: 0,
  x: -500
}}
animate={{
  opacity: 1,
  x: 0
}}
exit={{
  opacity: 0,
  x: 300
}}
transition={{
  type: "spring",
  mass: 1.35,
  stiffness: 25,
  duration: 1.5
}}
>
{children}
</motion.main> */}